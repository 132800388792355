export default class AppConfig {

    static get GCP_PROJECT_ID() {
        return "kujakuja-eastafrica-dev";
    }

    static get API_URL() {
        return "https://api.dev.eastafrica.kujakuja.com/api/v3/";
    }

    static get LOGIN_REQUIRED() {
        return true;
    }

    static get DEFAULT_LOGIN_EMAIL() {
        return "website@kujakuja.com";
    }

    static get DEFAULT_LOGIN_PASSWORD() {
        return "website_pass";
    }

    static get SITE_HEADER_DEFAULT_HIGHLIGHT_COLOUR() {
        return "#FFC300";
    }

    static get SITE_HEADER_DEFAULT_TITLE_TEXT() {
        return "Customer feedback";
    }

    static get GOOGLE_TRANSLATE_API_KEY() {
        return "AIzaSyCKm3R9M_MkhuuoCNkSrff8ekxVTCXJjNU";
    }

    static get TOP_KEYWORDS_ENABLED() {
        return false;
    }

    static get TOP_KEYWORDS_LOGIN_URL() {
        return "https://kujakuja.co/login";
    }

    static get TOP_KEYWORDS_LOGIN_USERNAME() {
        return "";
    }

    static get TOP_KEYWORDS_LOGIN_PASSWORD() {
        return "";
    }

    static get TOP_KEYWORDS_API_URL() {
        return "";
    }

    static get DATA_STUDIO_GRAPH_URL() {
        return "https://lookerstudio.google.com/embed/reporting/5da84d03-b65a-4c83-85f8-9e96d086dee7/page/p_6qfw21h2rc";
    }
}
